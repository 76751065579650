<template>
  <div>
    <v-container>
      <v-row>
        <h1 style="margin-right: 20px">
          Gedenkseiten
        </h1>
      </v-row>
      <v-row>
        <v-col cols="9">
          <router-link class="link" :to="{ name: 'StatisticsMemorialPageOverview' }">
            Grober Überblick
          </router-link>
          <router-link class="link" :to="{ name: 'StatisticsMemorialPageDetailed', params: { id: 'Gedenkseiten (Gesamt)'}  }">
            Detaillierter Überblick
          </router-link>
        </v-col>
        <v-col cols="3">
          <v-select
            outlined
            v-model="filterMonth"
            @change="filterTime()"
            :items="time"
            label="Zeitraum"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <div style="margin-top: 20px">
        <router-view
        :customPeriod="timePeriod"
        v-on:update="update($event)"
        ></router-view>
      </div>
    </v-container>

    <!-- Filter Table -->
    <v-container>
      <v-dialog max-width="600px" v-model="filterMonthDialog">
        <v-card style="padding: 20px">
          <v-alert
          v-if="errorPeriod"
          type="error">
            {{errorText}}
          </v-alert>
          <div style="padding: 20px; text-align: center">
            <h2>
              Bitte wähle den Zeitraum
            </h2>
            <v-container>
              <v-row>
                <v-col cols="6">
                  Start:
                  <input type="date" v-model="dateStart" @change="checkStartDate()" class="mb-8" style="border-style: solid; padding: 2px; border-radius: 4px" />
                  <v-btn style="width: 100%" @click="filterMonthDialog = false">
                    Abbrechen
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  End:
                  <input type="date" v-model="dateEnd" @change="checkEndDate()" class="mb-8" style="border-style: solid; padding: 2px; border-radius: 4px" />
                  <v-btn style="width: 100%" class="success" @click="filterPeriod()" :disabled="errorPeriod">
                    Tabelle filtern
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
export default {
  data () {
    return {
      timePeriod: {
        changed: false,
        period: {
          start: null,
          end: null
        }
      },
      time: ['Aktueller Monat', 'Benutzerdefiniert'],
      filterMonth: 'Aktueller Monat',
      errorText: '',
      errorPeriod: false,
      filterMonthDialog: false,
      dateNow: (new Date(Date.now())).toISOString().substr(0, 10),
      dateStart: (new Date(Date.now())).toISOString().substr(0, 10),
      dateEnd: (new Date(Date.now())).toISOString().substr(0, 10)
    }
  },
  methods: {
    checkStartDate () {
      const dateStart = this.dateStart.split('-')
      const dateEnd = this.dateEnd.split('-')
      const minDate = new Date(Date.UTC(2021, 6, 1, 0, 0, 0, 0))
      const auxStartDate = new Date(Date.UTC(parseInt(dateStart[0]), parseInt(dateStart[1]) - 1, parseInt(dateStart[2]), 0, 0, 0, 0))
      const auxEndDate = new Date(Date.UTC(parseInt(dateEnd[0]), parseInt(dateEnd[1]) - 1, parseInt(dateEnd[2]), 0, 0, 0, 0))
      if (auxStartDate >= minDate && auxStartDate <= auxEndDate) {
        this.errorPeriod = false
      } else {
        this.errorPeriod = true
        this.errorText = 'the start date is invalid, need to be equal or higher then 2021-07-01 and lower then end Date'
      }
    },
    checkEndDate () {
      const dateStart = this.dateStart.split('-')
      const dateEnd = this.dateEnd.split('-')
      const maxDate = new Date(Date.now())
      const auxStartDate = new Date(Date.UTC(parseInt(dateStart[0]), parseInt(dateStart[1]) - 1, parseInt(dateStart[2]), 0, 0, 0, 0))
      const auxEndDate = new Date(Date.UTC(parseInt(dateEnd[0]), parseInt(dateEnd[1]) - 1, parseInt(dateEnd[2]), 0, 0, 0, 0))
      if (auxEndDate >= auxStartDate && auxEndDate <= maxDate) {
        this.errorPeriod = false
      } else {
        this.errorPeriod = true
        this.errorText = 'the end date is invalid, need to be equal or lower then current day and higher then end Date'
      }
    },
    filterPeriod () {
      const dateStart = this.dateStart.split('-')
      const dateEnd = this.dateEnd.split('-')
      const auxStartDate = new Date(Date.UTC(parseInt(dateStart[0]), parseInt(dateStart[1]) - 1, parseInt(dateStart[2]), 0, 0, 0, 0))
      const auxEndDate = new Date(Date.UTC(parseInt(dateEnd[0]), parseInt(dateEnd[1]) - 1, parseInt(dateEnd[2]), 0, 0, 0, 0))
      this.timePeriod.period = {
        start: auxStartDate,
        end: auxEndDate
      }
      this.timePeriod.changed = true
      this.filterMonthDialog = false
    },
    filterTime () {
      if (this.filterMonth === 'Aktueller Monat') {
        this.timePeriod.period = null
        this.timePeriod.changed = true
      } else if (this.filterMonth === 'Benutzerdefiniert') {
        this.filterMonthDialog = true
      } else {
        const aux = this.filterMonth.split(' ')
        this.timePeriod.period = {
          start: null,
          end: null
        }
        switch (aux[0]) {
          case 'Januar':
            this.timePeriod.period.start = new Date(Date.UTC(parseInt(aux[1]), 0, 1, 0, 0, 0, 0))
            this.timePeriod.period.end = new Date(Date.UTC(parseInt(aux[1]), 0, 31, 0, 0, 0, 0))
            this.timePeriod.changed = true
            break
          case 'Februar':
            this.timePeriod.period.start = new Date(Date.UTC(parseInt(aux[1]), 1, 1, 0, 0, 0, 0))
            this.timePeriod.period.end = new Date(Date.UTC(parseInt(aux[1]), 1, 28, 0, 0, 0, 0))
            this.timePeriod.changed = true
            break
          case 'März':
            this.timePeriod.period.start = new Date(Date.UTC(parseInt(aux[1]), 2, 1, 0, 0, 0, 0))
            this.timePeriod.period.end = new Date(Date.UTC(parseInt(aux[1]), 2, 31, 0, 0, 0, 0))
            this.timePeriod.changed = true
            break
          case 'April':
            this.timePeriod.period.start = new Date(Date.UTC(parseInt(aux[1]), 3, 1, 0, 0, 0, 0))
            this.timePeriod.period.end = new Date(Date.UTC(parseInt(aux[1]), 3, 30, 0, 0, 0, 0))
            this.timePeriod.changed = true
            break
          case 'Mai':
            this.timePeriod.period.start = new Date(Date.UTC(parseInt(aux[1]), 4, 1, 0, 0, 0, 0))
            this.timePeriod.period.end = new Date(Date.UTC(parseInt(aux[1]), 4, 31, 0, 0, 0, 0))
            this.timePeriod.changed = true
            break
          case 'Juni':
            this.timePeriod.period.start = new Date(Date.UTC(parseInt(aux[1]), 5, 1, 0, 0, 0, 0))
            this.timePeriod.period.end = new Date(Date.UTC(parseInt(aux[1]), 5, 30, 0, 0, 0, 0))
            this.timePeriod.changed = true
            break
          case 'Juli':
            this.timePeriod.period.start = new Date(Date.UTC(parseInt(aux[1]), 6, 1, 0, 0, 0, 0))
            this.timePeriod.period.end = new Date(Date.UTC(parseInt(aux[1]), 6, 31, 0, 0, 0, 0))
            this.timePeriod.changed = true
            break
          case 'August':
            this.timePeriod.period.start = new Date(Date.UTC(parseInt(aux[1]), 7, 1, 0, 0, 0, 0))
            this.timePeriod.period.end = new Date(Date.UTC(parseInt(aux[1]), 7, 31, 0, 0, 0, 0))
            this.timePeriod.changed = true
            break
          case 'September':
            this.timePeriod.period.start = new Date(Date.UTC(parseInt(aux[1]), 8, 1, 0, 0, 0, 0))
            this.timePeriod.period.end = new Date(Date.UTC(parseInt(aux[1]), 8, 30, 0, 0, 0, 0))
            this.timePeriod.changed = true
            break
          case 'Oktober':
            this.timePeriod.period.start = new Date(Date.UTC(parseInt(aux[1]), 9, 1, 0, 0, 0, 0))
            this.timePeriod.period.end = new Date(Date.UTC(parseInt(aux[1]), 9, 31, 0, 0, 0, 0))
            this.timePeriod.changed = true
            break
          case 'November':
            this.timePeriod.period.start = new Date(Date.UTC(parseInt(aux[1]), 10, 1, 0, 0, 0, 0))
            this.timePeriod.period.end = new Date(Date.UTC(parseInt(aux[1]), 10, 30, 0, 0, 0, 0))
            this.timePeriod.changed = true
            break
          case 'Dezember':
            this.timePeriod.period.start = new Date(Date.UTC(parseInt(aux[1]), 11, 1, 0, 0, 0, 0))
            this.timePeriod.period.end = new Date(Date.UTC(parseInt(aux[1]), 11, 31, 0, 0, 0, 0))
            this.timePeriod.changed = true
            break
        }
      }
    },
    update (changeStatus) {
      this.timePeriod.changed = changeStatus
    },
    setUpTime () {
      const today = new Date(Date.now())
      const minDate = new Date(Date.UTC(2021, 6, 1, 0, 0, 0, 0))
      var months = today.getMonth() - minDate.getMonth() + (12 * (today.getFullYear() - minDate.getFullYear()))
      for (var aux = 0; aux < months; aux++) {
        today.setMonth(today.getMonth() - 1)
        switch (today.getMonth()) {
          case 0:
            this.time.push('Januar ' + today.getFullYear())
            break
          case 1:
            this.time.push('Februar ' + today.getFullYear())
            break
          case 2:
            this.time.push('März ' + today.getFullYear())
            break
          case 3:
            this.time.push('April ' + today.getFullYear())
            break
          case 4:
            this.time.push('Mai ' + today.getFullYear())
            break
          case 5:
            this.time.push('Juni ' + today.getFullYear())
            break
          case 6:
            this.time.push('Juli ' + today.getFullYear())
            break
          case 7:
            this.time.push('August ' + today.getFullYear())
            break
          case 8:
            this.time.push('September ' + today.getFullYear())
            break
          case 9:
            this.time.push('Oktober ' + today.getFullYear())
            break
          case 10:
            this.time.push('November ' + today.getFullYear())
            break
          case 11:
            this.time.push('Dezember ' + today.getFullYear())
            break
        }
      }
    }
  },
  created () {
    this.setUpTime()
  }
}
</script>

<style scoped>
.link {
  padding-top: 14px;
  margin-left: 20px;
  text-decoration: none;
  color: var(--v-text-base)
}

.router-link-active{
  text-decoration: underline;
}

.selected {
  text-decoration: underline;
}
</style>
